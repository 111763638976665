import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import { getResourceList } from '../../models/BaseModel'
import { ImageReplacementAnchor, InlineNavigation, Loading, LogoNavigation, DrawerNavigation, BlankErrorBoundary } from '@scrippsproduct/scripps-external-component-library'

import './../../scss/Header.scss'
import gritLogo from './../../images/grit-logo.png'

export default function Header() {
    const [menuLinks, setMenuLinks] = useState([])
    const [logoItems, setLogoItems] = useState([])
    const {isLoading, isSuccess, data} = useQuery(['header', {resourceName: 'header'}], getResourceList)
    const mobileNavRef = useRef();

    useEffect(() => {
        if (isSuccess) {
            setMenuLinks(data.get('menuLinks'))
            setLogoItems(data.get('logoItems'))
        }
    }, [isSuccess, data])

    const handleScroll = useDebouncedCallback(() => {
        if (window.scrollY > 10) {
            mobileNavRef.current.classList.add('mobile-nav--shadow')
        } else {
            mobileNavRef.current.classList.remove('mobile-nav--shadow')
        }
        }, 300) 

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        };
    }, [handleScroll])
    
    return (
        <header>
            <content-constrainer class="desktop-nav">
                <ImageReplacementAnchor href={`/`} 
                    image={{
                        url: gritLogo, 
                        width: 270, 
                        height: 100,
                        altText: 'Grit Logo, go to home page'
                    }} 
                    height={3}
                    baselineAdjustment={0}
                    classname="main-logo"
                />
                { (isLoading) && <Loading />}
                <header-menu-wrapper>
                    { (isSuccess) && <BlankErrorBoundary>
                        <InlineNavigation 
                            links={menuLinks} 
                            classname="main-navigation" 
                            position='right'
                        />
                    </BlankErrorBoundary> } 
                    { (isSuccess) && <BlankErrorBoundary>
                        <LogoNavigation 
                            items={logoItems}
                        />
                    </BlankErrorBoundary> }
                </header-menu-wrapper>
            </content-constrainer>
            <nav className="mobile-nav" ref={mobileNavRef}>
                <content-constrainer class="content-constrainer--no-border">
                    <BlankErrorBoundary >
                        <DrawerNavigation
                            links={[
                                {
                                    type: 'internal-link',
                                    href: '/',
                                    label: 'Home',
                                },
                                ...menuLinks]} 
                            items={logoItems}
                            logo={{
                                image: {
                                    url: gritLogo, 
                                    width: 270, 
                                    height: 100,
                                },
                                href: '/',
                                height: 3.5,
                                baselineAdjustment: -0.9
                            }}
                            classname="main-navigation"
                            clipPathStart="polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"
                            clipPathEnd="polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
                            overlayAppendEl={document.querySelector('site-wrapper')}
                        />
                    </BlankErrorBoundary>
                </content-constrainer>
            </nav>
        </header>
    )
}
